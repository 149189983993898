import './bootstrap';

import { createApp, h } from 'vue';
import { createInertiaApp, router } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import VueSelect from "vue-select";

//import { InertiaProgress } from '@inertiajs/progress';

import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { VueMaskDirective } from 'v-mask';
import Toast from "vue-toastification";
import { vfmPlugin } from 'vue-final-modal'
// import * as Sentry from "@sentry/vue";

import { globalMixins } from './utils/globalMixins';

const toastOptions = {
    // You can set your default options here
    transition: "Vue-Toastification__fade",
};
// as a plugin
import InlineSvg from 'vue-inline-svg';

import NumericDirective from "@/utils/NumericDirective";

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Vendu Pay';

const vMaskV2 = VueMaskDirective;
const vMaskV3 = {
    beforeMount: vMaskV2.bind,
    updated: vMaskV2.componentUpdated,
    unmounted: vMaskV2.unbind
};

import '@/assets/style.scss';
import "vue-toastification/dist/index.css";
import '../css/app.css';

// Uses https://inertiajs.com/events

function inertiaRoutingInstrumentation(
    customStartTransaction,
    startTransactionOnPageLoad = true,
    startTransactionOnLocationChange = true,
) {
    let activeTransaction;
    let name;

    if (startTransactionOnPageLoad) {
        name = route().current();
        activeTransaction = customStartTransaction({
        name,
        op: 'pageload',
        metadata: {
            source: 'route',
        },
        });
    }

    if (startTransactionOnLocationChange) {
        router.on('before', (_to, _from) => {
        if (activeTransaction) {
            activeTransaction.finish();
        }

        const newName = route().current();

        if (newName !== name) {
            activeTransaction = customStartTransaction({
            name: newName,
            op: 'navigation',
            metadata: {
                source: 'route',
            },
            });
        }
        });

        router.on('finish', () => {
        activeTransaction.setName(route().current(), 'route');
        });
    }
}


createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {

        const app = createApp({ render: () => h(App, props) });

        /*
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            integrations: [
                new Sentry.BrowserTracing({
                    routingInstrumentation: inertiaRoutingInstrumentation,
                }),
                new Sentry.Replay(),
            ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 0.2,

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/app\.vendu\.app\/api/],

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,

            environment: import.meta.env.VITE_APP_ENV
        });
        */

        app.component('inline-svg', InlineSvg)
            .component("v-select", VueSelect)
            .directive('mask', vMaskV3)
            .use(LottieAnimation)
            .use(NumericDirective)
            .use(Toast, toastOptions)
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(vfmPlugin)
            .mixin({ methods:
                {
                    route,
                    asset: window.Vapor.asset
                }
            })
            .mixin(globalMixins)
            .mount(el);

        return app;
    },
    progress: {
        color: '#1a5699',
        showSpinner: true
    },
})
